import { io } from 'socket.io-client';
import { getDefinKeyFromLocalStorage } from './utilities'

let socket;

const socketConnect = () => {
    socket = io("https://miniapp.defin.ai", {
        path: '/service/socket.io',
        query: {
            token: getDefinKeyFromLocalStorage()
        }
    });
    return socket;
};

export default socketConnect;
